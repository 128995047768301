class MainMenu {
  constructor(element) {
    this.element = element;
    this.mainMenuHumburger = this.element.querySelector('.js_main-menu_mobile');

    this.mainMenuHumburger.addEventListener('click', (event) => this.clickEventHandler(event));

  }

  clickEventHandler(event) {
  	let el = event.target;
    $('body').toggleClass('menu-is-open');
    el.classList.toggle('active');
    if(el.classList.contains('active')){
    	el.nextElementSibling.classList.add('active');
    } else {
    	el.nextElementSibling.classList.remove('active');
    }
  }
}

export default MainMenu;
